<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink routerLink="" [state]="{flag:0}" routerLinkActive="selected" group="staticQR">
        <a appAccordionToggle routerLink="">
            <mat-icon>select_all</mat-icon>Generate QR
        </a>
    </mat-list-item>
    <mat-list-item appAccordionLink *ngIf="service.getLocalStorage('authToken')" routerLink="/dashboard"
        routerLinkActive="selected" group="dashboard">
        <a appAccordionToggle routerLink="/dashboard">
            <mat-icon>dashboard</mat-icon>Dashboard
        </a>
    </mat-list-item>
    <mat-divider>
    </mat-divider><br>
    <mat-list-item appAccordionLink *ngIf="service.getLocalStorage('authToken') && settingsShow" routerLink="/settings"
        routerLinkActive="selected" group="settings">
        <a appAccordionToggle routerLink="/settings">
            <mat-icon>content_paste</mat-icon> Settings
        </a>
    </mat-list-item>
    <!-- <mat-list-item appAccordionLink routerLink="" [state]="{flag:1}" group="about-us">
        <a appAccordionToggle routerLink="/about-us">
            <mat-icon>content_paste</mat-icon> About Us
        </a>
    </mat-list-item> -->
    <mat-list-item [attr.href]="environment.companyURL" target="_blank" group="contact">
        <a href="{{environment.companyUrlContactUs}}" target="_blank">
            <mat-icon>content_paste</mat-icon> Contact us
        </a>
    </mat-list-item>
</mat-nav-list>