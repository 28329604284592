<div>
  <span>
    <h4 mat-dialog-title>Please login to view QR code details</h4>
  </span>

  <div mat-dialog-content class="flex-box">
    <mat-progress-spinner style="margin: 0 auto;top: 50%;" *ngIf="loading" color="primary" mode="indeterminate">
    </mat-progress-spinner>
    <mat-card *ngIf="!loading">
      <mat-card-content>
        <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="form-element">
            <mat-form-field>
              <input matInput placeholder="Username" formControlName="userName">
              <mat-error *ngIf="!formGroup.controls['userName'].valid && formGroup.controls['userName'].touched">
                This field is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-element">
            <mat-form-field>
              <input matInput type="password" placeholder="Password" formControlName="password">
              <mat-error *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
                {{ getErrorPassword() }}
              </mat-error>
            </mat-form-field>
          </div>
          <br>
          <div gdAreas="side content" gdRows="auto">
            <div gdArea="side" fxLayoutAlign="flex-start">
              <button type="button" mat-raised-button color="primary" (click)="goToRegister()">Create New
                Account</button>
            </div>
            <div gdArea="content" fxLayoutAlign="flex-end">
              <button type="submit" mat-raised-button [disabled]="!formGroup.valid" color="primary">Login</button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>