import moment from 'moment-timezone';
import { AES } from 'crypto-ts';

const GOOGLE_MAP_KEY = 'AIzaSyDUmjLxl_qEb35uInhGq4ncy_a5pz2YYxA'; // YOUR_GOOGLE_MAP_KEY
const appName = 'Dynamic QR Code'; // YOUR_APP_NAME
const companyURL = 'https://www.elsner.com'; // YOUR_COMPANY_URL
const companyName = 'Elsner Technologies Pvt. Ltd.'; // YOUR_COMPANY_NAME
const defaultQR = 'https://www.elsner.com'; // YOUR_DEFAULT_QR_CODE_TEXT

const logo = 'assets/elsner-logo.png';
const favicon = 'assets/images/favicon.png';
const pagination = [5, 10, 25, 100];
const pageSize = 10;
const secretKey = 'QR@123$';
const urlReg: RegExp = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&\'\(\)\*\+,;=.]+$/;
const telReg: RegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function flash(snak: any, message: any, action = 'X') {
  snak.open(message, action, {
    duration: 5000,
    verticalPosition: 'top'
  });
}

function encryptString(s: any, secret = null) {
  return AES.encrypt(s, secret ? secret : secretKey).toString();
}

function date(dt: any) {
  return moment(dt)
    .local().toDate();
}

const qrConfigData: any = {
  isPrivate: false,
    width: 240,
    height: 240,
    quietZone: 5,
    colorDark: '#000000',
    colorLight: '#ffffff',
    logo: null,
    logoWidth: null,
    logoHeight: null,
    logoBackgroundTransparent: false,
    logoBackgroundColor: '#ffffff',
    backgroundImage: null,
    backgroundImageAlpha: 1,
    autoColor: false,
    title: '',
    titleFont: 10,
    titleColor: '#000',
    titleBackgroundColor: '#fff',
    titleHeight: 50,
    titleTop: 10,
    subTitle: '',
    // subTitleFont: null,
    subTitleColor: '#004284',
};

export const configs = {
  GOOGLE_MAP_KEY,
  appName,
  companyURL,
  companyName,
  logo,
  favicon,
  pagination,
  pageSize,
  secretKey,
  urlReg,
  telReg,
  emailregex,
  flash,
  encryptString,
  date,
  qrConfigData,
  defaultQR,
};
