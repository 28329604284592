import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Service } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-login-modal',
    templateUrl: './loginModal.component.html',
    styleUrls: ['./loginModal.component.scss']
})
export class LoginModalComponent implements OnInit {

    formGroup: FormGroup;
    public environment = environment;
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<LoginModalComponent>,
        private formBuilder: FormBuilder,
        private router: Router,
        private service: Service,
        public snackBar: MatSnackBar) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.formGroup = this.formBuilder.group({
            userName: new FormControl(null, Validators.required),
            password: new FormControl(null, [Validators.required, this.checkPassword])
        });
    }

    checkPassword(control: { value: any; }) {
        const enteredPassword = control.value;
        const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
        return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { requirements: true } : null;
    }

    getErrorPassword() {
        return this.formGroup.get('password').hasError('required') ? 'This field is required' :
            this.formGroup.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
    }

    goToRegister() {
        this.router.navigate(['/auth/register']);
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.formGroup.valid && !this.loading) {
            this.loading = true;
            const userData = {
                userName: this.formGroup.controls.userName.value,
                password: this.environment.encryptString(this.formGroup.controls.password.value)
            };
            this.service.makeAPICall(this.service.postMethod, this.service.loginAPI, userData, (response) => {
                this.loading = false;
                if (response.code !== 0) {
                    if (response.code === 200) {
                        localStorage.setItem('authToken', response.authToken);
                        localStorage.setItem('userInfo', JSON.stringify(response.data));
                        this.dialogRef.close();
                    } else {
                        environment.flash(this.snackBar, response.message);
                    }
                } else {
                    environment.flash(this.snackBar, 'Something went wrong!');
                }
            });
        }
    }
}
