// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
'use strict';

import { configs } from './config';

const baseUrl = 'https://www.dynamic-qrcodegenerator.com';
const serverApiBasePath = 'https://api.dynamic-qrcodegenerator.com';
const serverBasePath = 'https://api.dynamic-qrcodegenerator.com';

const imagePath = serverBasePath + '/settings';
const companyUrlContactUs = 'https://www.elsner.com/contact-us/'



export const environment = {
  production: false,
  baseUrl,
  serverApiBasePath,
  serverBasePath,
  imagePath,
  companyUrlContactUs,
  ...configs
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
