import { MediaMatcher } from '@angular/cdk/layout';
import { Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss'],
})
export class FullComponent implements OnDestroy, AfterViewInit, OnInit {
  mobileQuery: MediaQueryList;
  @Input() isSpinnerVisible = false;

  private mobileQueryListener: () => void;
  public environment = environment;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    public route: Router,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }
  ngAfterViewInit(): void {
    const node = document.createElement('script');
    node.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAP_KEY}&libraries=places&language=en`;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);

    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      settings.forEach((element: { name: string; status: boolean }) => {
        if (element.name === 'logo.png' && element.status === true) {
          document
            .getElementById('appLogo')
            .setAttribute('src', `${environment.imagePath}/logo.png`);
        }
      });
    }
  }
}
