import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-config',
    templateUrl: './qrConfig.component.html',
    styleUrls: ['./qrConfig.component.scss']
})
export class ConfigQRComponent implements OnInit {

    formGroup: FormGroup = this.formBuilder.group({
        isPrivate: false,
        width: 256,
        height: 256,
        quietZone: 0,
        colorDark: '#000000',
        colorLight: '#ffffff',
        logo: null,
        logoWidth: null,
        logoHeight: null,
        logoBackgroundTransparent: false,
        logoBackgroundColor: '#ffffff',
        backgroundImage: null,
        backgroundImageAlpha: 1,
        autoColor: false,
        title: '',
        titleFont: 10,
        titleColor: '#000',
        titleBackgroundColor: '#fff',
        titleHeight: 0,
        titleTop: 10,
        subTitle: '',
        // subTitleFont: null,
        subTitleColor: '#004284',
    });

    isDashboard: boolean = false;

    constructor(
    public domSanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ConfigQRComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) { }

    ngOnInit(): void {
        if (this.data) {
            delete this.data.text;
            if (this.data.isDashboard) {
                this.isDashboard = this.data.isDashboard
                delete this.data.isDashboard;
            }
            this.data.titleFont = parseInt(this.data.titleFont);
            console.log(this.data,"this.data");
            
            this.formGroup.patchValue(this.data);
        }
    }
    onImagePicked(event: HTMLInputElement, flag = 0) {
        const file = (event as HTMLInputElement).files[0];
        const reader = new FileReader();
        reader.onload = () => {
            if (flag === 0) {
                this.formGroup.patchValue({
                    logo: reader.result
                });
            } else {
                this.formGroup.patchValue({
                    backgroundImage: reader.result
                });
            }
        };
        reader.readAsDataURL(file);
        event.value = '';
    }

    deletefile(flag = 0) {
        if (flag === 0) {
            this.formGroup.patchValue({
                logo: null
            });
        } else {
            this.formGroup.patchValue({
                backgroundImage: null
            });
        }
    }

    onSubmit(val = null) {
        if (!val) {
            this.dialogRef.close();
        } else {
            this.dialogRef.close(val);
        }
    }

}
